<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex align-center pt-3 mr-5 pt-3 flex-md-wrap col-10 col-xl-8">
                    <v-text-field
                        :placeholder="$t('message.wprNo')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-175 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchWprs('title')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchWprs('title')"
                    />
                    <v-text-field
                        :placeholder="$t('message.poNo')"
                        :value="searchValues.poNumber"
                        autcomplete="new-password"
                        class="filter-width-175 ml-2 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.poNumber = $event"
                        @click:append="searchWprs('poNumber')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchWprs('poNumber')"
                    />
                    <v-select
                        :items="filterItems.status"
                        :loading="loading.filterItems.status"
                        :placeholder="$t('message.status')"
                        class="filter-width-150 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Status.title"
                        item-value="Status.id"
                        solo
                        v-model="filterValues.status"
                        @change="filterWprs()"
                    >
                        <template v-slot:item="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                        <template v-slot:selection="{ item }">
                            <span class="font-sm">{{ $t('message.' + item.Status.title) }}</span>
                        </template>
                    </v-select>
                    <div class="col-12 height-10px pa-0" v-if="$vuetify.breakpoint.mdAndDown"></div>
                    <v-select
                        :class="'filter-width-150' + ($vuetify.breakpoint.lgAndUp ? ' ml-2' : '')"
                        :items="filterItems.company"
                        :loading="loading.filterItems.company"
                        :placeholder="$t('message.company')"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.company"
                        @change="filterWprs()"
                    />
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.supplier')"
                        class="filter-width-150 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterWprs()"
                    />
                    <v-btn
                        :loading="loading.clear"
                        class="ml-2 px-2"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer/>
                <div class="d-flex flex-row pt-3 mr-5 align-row">
                    <div class="d-flex flex-column">
                        <div class="font-sm">
                            <v-icon small color="gray lighten-1">fiber_manual_record</v-icon>{{ $t('message.open') }}
                        </div>
                        <div class="font-sm">
                            <v-icon small color="yellow lighten-1">fiber_manual_record</v-icon>{{ $t('message.submitted') }}
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <div class="font-sm ml-3">
                            <v-icon small color="green lighten-1">fiber_manual_record</v-icon>{{ $t('message.approved') }}
                        </div>
                        <div class="font-sm">
                            <v-icon small color="red lighten-4">fiber_manual_record</v-icon>{{ $t('message.declined') }}
                        </div>
                    </div>
                </div>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="WarehousePurchaseRequests"
                    :export-fields="headers"
                    :export-source="'wprs'"
                    class="ml-3"
                />
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loading.wprs"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
            :items="WarehousePurchaseRequests"
            :options="tableOptions"
            :server-items-length="totalWarehousePurchaseRequests"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            id="wprTable"
            item-key="Wpr.id"
            @update:options="updateDataTable"
        >
            <template v-slot:header.Wpr.Specifications="{ header }">
                <v-row no-gutters>
                    <v-col>{{ $t('message.species') }}</v-col>
                    <v-col>{{ $t('message.grade') }}</v-col>
                    <v-col class="text-right">{{ $t('message.qty') }}</v-col>
                </v-row>
            </template>
            <template v-slot:item.Wpr.id="{ item }">
                <div  class="d-flex flex-row align-center justify-center" style="height: 100%">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="updateWpr(item.Wpr.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ item.Wpr.status_id == 3 ? $t('message.viewWpr') : $t('message.updateWpr') }}</v-list-item>
                            <v-list-item class="font-sm" @click="updatePurchaseOrder(item.Wpr.purchaseorder_id)" v-if="item.Wpr.purchaseorder_id != null && $can('update','Po')"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePurchaseOrder') }}</v-list-item>
                            <v-list-item v-if="item.Wpr.status_id == 1" class="font-sm" @click="cancelWpr(item.Wpr.id, item.Wpr.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelWpr') }}</v-list-item>
<!--                            <v-list-item class="font-sm" @click="viewPdf(item.Wpr.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
                            <PrintWprButton
                                :list-mode="true"
                                :update-mode="true"
                                :wpr-id="item.Wpr.id"
                            />
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:item.Wpr.status_id="{ item }">
                <div class="text-center">
                    <v-icon small :color="item.Wpr.Status.color + ' lighten-1'">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Wpr.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Wpr.title }}</span>
            </template>
            <template v-slot:item.Wpr.Purchaseorder.title="{ item }">
                <div  class="d-flex flex-row align-center" style="height: 100%" v-if="item.Wpr.purchaseorder_id == null">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="px-1"
                                outlined
                                small
                                v-if="item.Wpr.purchaseorder_id == null && item.Wpr.status_id == 3 && $can('convert','Wpr')"
                                v-on="on"
                                @click="toggleConvertDialog(item)"
                            ><v-icon x-small>add</v-icon> {{$t('message.convertToPo')}}</v-btn>
                            <span v-if="item.Wpr.purchaseorder_id == null && item.Wpr.status_id != 3">{{ $t('message.na') }}</span>
                        </template>
                        <span>{{ $t('message.convert') }}</span>
                    </v-tooltip>
                </div>
                <div v-if="item.Wpr.purchaseorder_id != null && $can('update','Po')">
                    <router-link :to="{name: 'update_purchaseorder', params: { purchaseOrderId: item.Wpr.purchaseorder_id }}" class="text-no-wrap">{{ item.Purchaseorder.title }}</router-link>
                </div>
                <div v-else>{{ item.Purchaseorder.title }}</div>
            </template>
            <template v-slot:item.Wpr.wprdate="{ item }">
                {{ item.Wpr.wprdate != '0000-00-00' ? formatDate(item.Wpr.wprdate) : '' }}
            </template>
            <template v-slot:item.Wpr.Customer.title="{ item }">
                <span class="text-no-wrap">{{ item.Wpr.Customer.otsname != '' ? item.Wpr.Customer.otsname : item.Wpr.Customer.title }}</span>
            </template>
            <template v-slot:item.Wpr.Supplier.title="{ item }">
                <span>{{ item.Wpr.Supplier.code != '' ? item.Wpr.Supplier.code : item.Wpr.Supplier.title }}</span>
            </template>
            <template v-slot:item.Wpr.paymentstatement_id="{ item }">
                <span>{{ item.Wpr.Paymentstatement.title }}</span>
            </template>
            <template v-slot:item.Wpr.cfrvalue="{ item }">
                <div class="flex flex-row pl-0">
                    <span class="pr-1">{{ currency(item.Wpr.currency_id) }}</span>
                    <span>{{ formatThisNumber(item.Wpr.cfrvalue,'0,0.00') }}</span>
                </div>
            </template>
            <template v-slot:item.Wpr.Specifications="{ item }">
                <v-row v-for="(specification, idx) in item.Wpr.Specifications" no-gutters v-bind:key="idx">
                    <v-col class="text-no-wrap" :class="idx > 0 ? 'border-top-1' : ''">{{ specification.species }}</v-col>
                    <v-col class="text-no-wrap" :class="idx > 0 ? 'border-top-1' : ''">{{ specification.grade }}</v-col>
                    <v-col class="text-right" :class="idx > 0 ? 'border-top-1' : ''">{{ formatThisNumber(specification.volume,'0,0.00')  }}</v-col>
                </v-row>
            </template>
            <template v-slot:item.Wpr.containercount="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Wpr.containersize_id) ">{{ item.Wpr.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Wpr.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Wpr.containersize_id) }}</span>
                </div>
            </template>
        </v-data-table>
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        ></SimpleAlert>
        <v-dialog
            overlay=false
            scrollable
            transition="dialog-bottom-transition"
            v-model="convertDialog"
            width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ wprTitleToConvert + ": " + $t('message.wprConvertThisToPo') }}</v-card-title>
                <v-card-text>
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="4">{{ $t('message.newPoNumber') }}</v-col>
                        <v-col cols="8">
                            <v-text-field
                                autocomplete="off"
                                dense
                                hide-details
                                solo
                                @change="newPurchaseOrderNo = $event"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="pt-3">
                        <v-col class="red--text font-italic">{{ $t('message.wprConvertNote') }}</v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="convertDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                        :loading="loading.convert"
                        class="ml-3"
                        color="info"
                        small
                        @click="convertWprToPo(wprIdToConvert)"
                    >{{ $t('message.convert') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {numberFormat} from "../../helpers/helpers";
import {mapGetters, mapActions, mapMutations} from "vuex";
import {mapFields} from "vuex-map-fields";
import { v4 as uuidv4 } from 'uuid';
import {api} from "Api";

const SimpleAlert = () => import("./SimpleAlert");
const ExportTableJson = () => import("Components/Appic/ExportTableJson");
const PrintWprButton = () => import("Components/Appic/Printing/PrintWprButton.vue");

export default {
    name: "WprsListing",
    components: {ExportTableJson, PrintWprButton, SimpleAlert},
    data() {
        return {
            convertDialog: false,
            currentFilter: null,
            currentSearch: null,
            dialogs: {
                error:  false,
                error_message: ""
            },
            exportConditions: {},
            filterItems: {
                company: [],
                status: [],
                supplier: []
            },
            filterOptions: {},
            filterValues: {
                company: null,
                status: null,
                supplier: null
            },
            hideDefaultHeader: false,
            hideDefaultFooter: false,
            loading: {
                filter: {
                    statuses: false,
                    suppliers: false
                },
                filterItems: {
                    company: false,
                    status: false,
                    supplier: false
                },
                search: false,
                wprs: false
            },
            newPurchaseOrderNo: null,
            searching: false,
            searchField: 'Wpr.title', //default search field
            searchTerm: null,
            searchValues: {
                poNumber: null,
                title: null
            },
            tableOptions: {},
            wprIdToConvert: null,
            wprTitleToConvert: null,
        }
    },
    computed: {
        ...mapFields('wpr', {
            totalWarehousePurchaseRequests: 'current.totalWarehousePurchaseRequests',
            WarehousePurchaseRequests: 'current.WarehousePurchaseRequests'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('wpr',{
            wprStatuses: 'wprStatuses',
            wprSuppliers: 'wprSuppliers'
        }),
        ...mapGetters([
            'containerSizes',
            'currencies'
        ]),
        headers () {
            let headers = [
                {
                    id: 0,
                    text: this.$t('message.actions'),
                    value: 'Wpr.id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.date'),
                    value: 'Wpr.wprdate',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.wprNo'),
                    value: 'Wpr.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.status'),
                    value: 'Wpr.status_id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 4,
                    text: this.$t('message.poNo'),
                    value: 'Wpr.Purchaseorder.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 5,
                    text: this.$t('message.company'),
                    value: 'Wpr.Customer.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 6,
                    text: this.$t('message.reasonForPurchase'),
                    value: 'Wpr.explanation',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 7,
                    text: this.$t('message.supplier'),
                    value: 'Wpr.Supplier.title',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-5-pct',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.speciesAndQty'),
                    value: 'Wpr.Specifications',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-20-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 9,
                    text: this.$t('message.container'),
                    value: 'Wpr.containercount',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 10,
                    text: this.$t('message.cost'),
                    value: 'Wpr.cfrvalue',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 11,
                    text: this.$t('message.paymentTerms'),
                    value: 'Wpr.paymentstatement_id',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: false
                }
            ]
            return headers
        },
        searchFields() {
            return this.headers.filter((header)=>header.searchable === true)
        },
    },
    methods: {
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('wpr',{
            cancelWprById: 'cancelWprById',
            convertWprToPoById: 'convertWprToPoById',
            getAllWprs: 'getAllWprs',
            getAllWprStatuses: 'getAllWprStatuses',
            getAllWprSuppliers: 'getAllWprSuppliers',
            searchAllWprs: 'searchAllWprs'
        }),
        async cancelWpr (val, title) {
            if(await this.$root.$confirm(this.$t('message.cancelWpr') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueWprCancelAction'), {color: 'orange'})){
                this.cancelWprById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.wprDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadWprs()
                        } else {
                            this.$toast.error(this.$t('message.errors.wprNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.wprNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        clearFilters(){
            this.loading.clear = true
            this.filterValues.company = null
            this.filterValues.status = 0
            this.filterValues.supplier = null
            this.searching = false
            this.searchValues.title = null
            this.searchValues.poNumber = null
            this.tableOptions.page = 1
            this.tableOptions.sortBy[0] = 'Wpr.wprdate'
            this.tableOptions.sortDesc[0] = true
            this.loading.clear = false
            this.filterWprs()
        },
        convertWprToPo (val){
            if(this.newPurchaseOrderNo == null){
                this.dialogs.error_message = this.$t('message.errors.poNumberCannotBeEmpty');
                this.dialogs.error = true
            } else {
                this.loading.convert = true
                let payload = {
                    wpr_id: val,
                    po_number: this.newPurchaseOrderNo,
                    setPurchaseOrder: false
                }
                this.convertWprToPoById(payload)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.wprConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.convert = false
                            this.newPurchaseOrderNo = null
                            this.wprIdToConvert = null
                            this.wprTitleToConvert = null
                        } else {
                            this.$toast.error(this.$t('message.errors.wprNotConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.wprNotConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.convert = false
                        this.newPurchaseOrderNo = null
                        this.wprIdToConvert = null
                        this.wprTitleToConvert = null
                        this.convertDialog = false
                        this.loadWprs()
                    })
            }
        },
        currency(currency_id) {
            return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterWprs() {
            this.searchValues.title = null
            this.searchValues.poNumber = null
            let noValues = true
            const keys = Object.keys(this.filterValues)
            keys.forEach((key, index) => {
                if(this.filterValues[key] != null) noValues = false
            })
            if(noValues == false) {
                let conditions = []
                if(this.filterValues.company != null) conditions.push({
                    field: 'Wpr.customer_id',
                    value: this.filterValues.company
                })
                if(this.filterValues.status != null) conditions.push({
                    field: 'Wpr.status_id',
                    value: this.filterValues.status
                })
                if(this.filterValues.supplier != null) conditions.push({
                    field: 'Wpr.supplier_id',
                    value: this.filterValues.supplier
                })

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                if (_.isEqual(this.currentFilter, this.filterValues) === false) {
                    this.tableOptions.page = 1
                    this.currentFilter = _.cloneDeep(this.filterValues)
                }

                if(_.has(this.tableOptions, 'sortBy') == false) {
                    this.tableOptions['sortBy'] = ['Wpr.wprdate']
                }

                if(this.tableOptions.sortBy.length == 0){
                    this.tableOptions['sortBy'] = ['Wpr.wprdate']
                }
                if(_.has(this.tableOptions, 'sortDesc') == false){
                    this.tableOptions['sortDesc'] = [false]
                    if(this.tableOptions.sortBy[0] == 'Wpr.wprdate') {
                        this.tableOptions['sortDesc'] = [true]
                    }
                }
                if(this.tableOptions.sortBy[0] == 'Wpr.wprdate' && this.tableOptions.sortDesc.length == 0){
                    this.tableOptions['sortDesc'] = [true]
                }

                let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                let payload = {
                    tableOptions: this.tableOptions,
                    conditions: conditions,
                    order: order
                }

                this.hideDefaultFooter = false
                this.loading.wprs = true;
                this.searchAllWprs(payload)
                    .then(() => {
                        this.loading.wprs = false;
                    })
                    .catch(()=>{
                        this.loading.wprs = false;
                    })
                    .finally(()=>{
                        this.loading.wprs = false;
                    })
            } else {
                this.loadWprs()
            }
        },
        formatDate,
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        getContainerSize (containerSizeId) {
            let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == containerSizeId)
            if( container ) {
                return container?.Containersize.title
            } else {
                return null
            }
        },
        loadFilterItems(filter) {
            return new Promise((resolve, reject) => {
                if(filter && filter.length > 0) {
                    this.loading.filterItems[filter] = true
                    api
                        .get('/wprs/filter-options/' + filter)
                        .then(response => {
                            if(response.data.status == 'success') {
                                this.filterItems[filter] = response.data.data

                                //add All to some options
                                if(filter == 'status'){
                                    this.filterItems[filter].unshift({Status: {id: 0, title: 'all' }})
                                }

                                this.loading.filterItems[filter] = false
                                resolve('done')
                            } else {
                                this.loading.filterItems[filter] = false
                                reject('error')
                            }
                        })
                        .catch(error => {
                            this.loading.filterItems[filter] = false
                            reject(error)
                        })
                } else {
                    reject('Filter empty')
                }
            })
        },
        loadWprs () {
            this.hideDefaultFooter = false
            this.loading.wprs = true;
            let payload = {
                tableOptions: this.tableOptions
            }
            this.getAllWprs(payload)
                .then(()=>{
                    this.loading.wprs = false;
                })
                .catch(()=>{
                    this.loading.wprs = false;
                })
                .finally(()=>{
                    this.loading.wprs = false;
                })
        },
        resetSearch () {
            this.searching = false
            this.hideDefaultFooter = false
            this.filterValues.status = 0
            this.tableOptions.page = 1
            if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10
            this.tableOptions.sortBy = []
            this.tableOptions.sortDesc = []
            this.filterWprs()
        },
        searchWprs( field ) {
            if(this.searchValues[field] == null){
                this.dialogs.error = true
                this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
            } else {
                this.searching = true
                this.filterValues.company = null
                this.filterValues.status = null
                this.filterValues.supplier = null

                const keys = Object.keys(this.searchValues)
                keys.forEach((key, index) => {
                    if(key != field) this.searchValues[key] = null
                })

                let fieldName = null
                let sortFieldName = null
                switch(field){
                    case 'title':
                        fieldName = 'Wpr.title'
                        sortFieldName = 'Wpr.wprdate'
                        break
                    case 'poNumber':
                        fieldName = 'Wpr.Purchaseorder.title'
                        sortFieldName = 'Wpr.wprdate'
                        break
                }

                // this.hideDefaultFooter = true
                this.loading.wprs = true;

                let payload = {}
                let order = []

                if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                    //new search = new sort
                    order.push({field: fieldName, direction: field == 'title' ? 'ASC' : 'DESC'})
                    this.tableOptions.sortBy[0] = sortFieldName
                    this.tableOptions.sortDesc[0] = field == 'title' ? false : true
                    this.currentSearch = _.cloneDeep(this.searchValues)
                    //new search new page
                    this.tableOptions.page = 1
                } else {
                    //same search new sort
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions.sortBy[0] = sortFieldName
                    }
                    order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                }

                if(fieldName != null) {
                    payload = {
                        tableOptions: this.tableOptions,
                        conditions: [
                            {
                                field: fieldName,
                                value: this.searchValues[field]
                            }
                        ],
                        order: order
                    }
                } else {
                    payload = {
                        tableOptions: this.tableOptions,
                        conditions: [],
                        order: order
                    }
                }

                this.searchAllWprs(payload)
                    .then(() => {
                        this.loading.wprs = false
                    })
                    .catch(()=>{
                        this.loading.wprs = false
                    })
                    .finally(()=>{
                        this.loading.wprs = false
                    })
            }
        },
        // searchWprsByPoNumber() {
        //     if(this.searchValues.poNumber == null){
        //         this.dialogs.error = true
        //         this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
        //     } else {
        //         this.searchValues.title = null
        //         this.hideDefaultFooter = true
        //         this.loading.wprs= true;
        //         let payload = {
        //             tableOptions: this.tableOptions,
        //             conditions: [
        //                 {
        //                     field: 'Wpr.Purchaseorder.title',
        //                     value: this.searchValues.poNumber
        //                 }
        //             ]
        //         }
        //         this.searchAllWprs(payload)
        //             .then(() => {
        //                 this.loading.wprs = false;
        //             })
        //             .catch(()=>{
        //                 this.loading.wprs = false;
        //             })
        //             .finally(()=>{
        //                 this.loading.wprs = false;
        //             })
        //     }
        // },
        toggleConvertDialog (wpr){
            this.convertDialog = !this.convertDialog
            this.wprIdToConvert = this.convertDialog ? wpr.Wpr.id : null
            this.wprTitleToConvert = this.convertDialog ? wpr.Wpr.title : null
        },
        updateDataTable(options) {
            this.tableOptions = options
            if(this.searching === true)  {
                // check which field is not empty
                let field = null
                if(this.searchValues.poNumber != null) field = 'poNumber'
                if(this.searchValues.title != null) field = 'title'
                this.searchWprs(field)
            } else {
                this.filterWprs()
            }
        },
        updatePurchaseOrder(purchaseOrderId) {
            let tab = window.open('/v1/purchaseorders/update/' + purchaseOrderId,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateWpr (val){
            let tab = window.open('/v1/wprs/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
            // router.push({name: 'update_wpr', params: { wprId : val}}).catch(err => {})
        },
        viewPdf (wprId) {
            let document = encodeURIComponent(this.WarehousePurchaseRequests.find( w => w.Wpr.id == wprId)?.Wpr.title)
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/wprs/print/'
                + wprId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
    },
    watch: {
        // filterOptions: {
        //     handler(){
        //         if(!this.loading.search) {
        //             this.loadWprs()
        //         }
        //     },
        //     deep: true
        // },
        // filterValues: {
        //     handler(values){
        //         this.searchValues.title = null
        //         this.searchValues.poNumber = null
        //         let noValues = true
        //         const keys = Object.keys(this.filterValues)
        //         keys.forEach((key, index) => {
        //             if(this.filterValues[key] != null) noValues = false
        //         })
        //         if(noValues == false) {
        //             this.filterWprs(values)
        //         } else {
        //             this.loadWprs()
        //         }
        //     },
        //     deep: true
        // },
        // tableOptions: {
        //     handler(){
        //         // this.loadWprs()
        //         if(this.searching === false) this.filterWprs()
        //     },
        //     deep: true
        // },
    },
    created() {
        // this.loading.filter.statuses = true
        // this.loading.filter.suppliers = true
        // this.getAllWprStatuses()
        //     .then(()=>{
        //         this.loading.filter.statuses = false
        //         this.getAllWprSuppliers()
        //             .then(()=>{
        //                 this.loading.filter.suppliers = false
        //                 this.loadWprs()
        //             })
        //     })

        // this.loadWprs()

        this.searchValues.poNumber = null
        this.searchValues.title = null
        this.filterValues.company = null
        this.filterValues.status = 0
        this.filterValues.supplier = null

        if(this.allCurrencies.length == 0) this.getAllCurrencies()

        if(this.filterValues.status == 0){
            this.filterWprs()
        } else {
            this.loadWprs()
        }

        if(this.filterItems.company.length == 0) this.loadFilterItems('company')
        if(this.filterItems.status.length == 0) this.loadFilterItems('status')
        if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
    }
}
</script>

<style>
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-175 {
    width: 175px !important;
    max-width: 175px !important;
}
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.border-top-1 {
    border-top: 1px solid lightgray !important;
}
.v-dialog {
    min-width: 200px !important;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.height-10px {
    height: 10px;
}
.v-data-table-header th {
    white-space: nowrap;
}
</style>